import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import classNames from 'classnames';
import { StoryItem } from './StoryItem/index';
import { parseLayoutTemplate } from '../utils/parseLayoutTemplate';
import './styles.themed.scss';
import { ColumnsContainer } from '../ColumnsContainer';
import { ColumnOfItems } from '../ColumnOfItems';

export function MultiStorylineContainer({ content }) {
  if (!content) return null;
  const {
    items,
    metadata,
  } = content;

  if (!items || !metadata) return null;

  const {
    packageTitle,
    packageTitleUrl,
    packageGoBigStyle,
    leadColumn,
    showBottomBorder = true,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA = true,
    showVerticalBorderB = true,
    columns,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column2Title,
    column3Title,
  } = metadata;

  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  const hasLeadColumn = hasColumn1Lead || hasColumn2Lead || hasColumn3Lead;

  // Get Layout Info
  // note: parseLayoutTemplate is looking for layoutTemplate but can depend on other metadata if needed.
  const layoutDef = parseLayoutTemplate(metadata);

  if (!layoutDef) {
    /* eslint-disable-next-line no-console */
    console.error('MultiStoryline ERROR: Invalid Layout');
    return null;
  }
  const { itemsPerColumn } = layoutDef;

  ///
  // Put the items into their column lists.
  const itemsCopy = [...items];
  const itemsByColumn = itemsPerColumn.reduce((ac, numItemsInColumn, index) => {
    for (let i = 0; i < numItemsInColumn; i += 1) {
      const colItems = ac[index] || [];
      const item = itemsCopy.shift();
      colItems.push(item);
      // eslint-disable-next-line no-param-reassign
      ac[index] = colItems;
    }
    return ac;
  }, []);

  // We track itemIndex, the index from the original items array,
  // because LiveBlog currently needs it. That could be changed but
  // arguably the item might want to know this information.
  let currentItemIndex = 0;

  const columnsJsx = itemsByColumn.reduce((ac, itemList, columnIndex) => {
    const columnNumber = columnIndex + 1;
    const columnName = `Column${columnNumber}`;
    const columnClass = `items-column-${columnNumber}`;
    const numItems = itemList.length;
    const numItemsTriple = numItems % 3 === 0;
    const numItemsEven = numItems % 2 === 0;
    const itemsCountClass = `items-count-class-${numItems < 3 ? numItems : '3plus'}`;
    const showLive = playmakerToggle && columnName === playmakerColumn;
    const isLeadColumn = leadColumn === `${columnName}Lead`;
    const isNotLeadColumn = leadColumn && !isLeadColumn;
    const innerColumnJoin = columnNumber < columns;
    const showBothVerticalBorders = (showVerticalBorderA && showVerticalBorderB);
    const showNoVerticalBorders = (!showVerticalBorderA && !showVerticalBorderB);
    const showOneVerticalBorder = !showBothVerticalBorders && !showNoVerticalBorders;

    // rowModeGroupNumber calc, syncs with classes to make the columns-in-row-mode
    let rowModeGroupNumber = 3;
    if (numItems < 3) {
      // 1 or 2 items, use 2 columns
      rowModeGroupNumber = 2;
    } else if (numItemsTriple) {
      // make sure if evenly divisible by 3, it's 3 columns
      rowModeGroupNumber = 3;
    } else if (numItemsEven) {
      // if evenly divisible by two and not by three, use 2 columns
      // currently with our max of 7 items, only affect 4 items.
      rowModeGroupNumber = 2;
    }
    let currentColumnTitle;
    switch (columnIndex) {
      case 0:
        currentColumnTitle = column1Title;
        break;
      case 1:
        currentColumnTitle = column2Title;
        break;
      default:
        currentColumnTitle = column3Title;
    }

    const itemEl = (
      <ColumnOfItems
        key={`${columnName}`}
        columnName={columnName}
        horizontalItemDividerClassName="horizontal-item-divider"
        rowModeDividerClassName="row-mode-horizontal-divider"
        rowModeGroupNumber={rowModeGroupNumber}
        columnTitle={currentColumnTitle}
        className={classNames(
          'multistory-items-column',
          columnClass, // items-column-N
          itemsCountClass, // needed for column-3 rearrange
          {
            'items-count-is-triple': numItemsTriple,
            'items-count-is-even': numItemsEven,
            'two-column': columns === 2,
            'three-column': columns === 3,
            'show-vertical-border-1': showVerticalBorderA,
            'show-vertical-border-2': showVerticalBorderB,
            'both-vertical-borders': showBothVerticalBorders,
            'one-vertical-border': showOneVerticalBorder,
            'lead-column': isLeadColumn,
            'not-lead-column': isNotLeadColumn,
            'has-lead-column': hasLeadColumn,
            'no-lead-column': !hasLeadColumn,
            'before-inner-vertical-join': innerColumnJoin,
          },
        )}
      >
        {
          itemList.reduce((jsxList, itemData, index) => {
            const storyItemJsx = itemData && (
              <StoryItem
                className={classNames(
                  'multistory-item',
                  {
                    'row-mode-860': hasLeadColumn && columnNumber === 3,
                  },
                )}
                key={`${itemData.id}-${columnName}`}
                content={content}
                itemData={itemData}
                showLive={showLive && index === 0}
                columnIndex={columnIndex}
                itemIndex={currentItemIndex}
                showVerticalBorder={showVerticalBorderA}
              />
            );

            // the currentItemIndex has to be tracked because
            // it's needed when the content item is a live blog.
            // <LiveBlog> gets the item from package data itself
            // at the given itemIndex.
            currentItemIndex += 1;

            jsxList.push(storyItemJsx);

            return jsxList;
          }, [])
        }
      </ColumnOfItems>
    );
    ac.push(itemEl);

    return ac;
  }, []);

  const verticalDividersList = columns === 3
    ? [showVerticalBorderA, showVerticalBorderB] : [showVerticalBorderA];
  return (
    <div
      className={classNames(
        'multistory-container',
        {
          'two-column': columns === 2,
          'three-column': columns === 3,
          'has-lead-column': hasLeadColumn,
          'no-lead-column': !hasLeadColumn,
          'package-go-big': packageGoBigStyle,
        },
      )}
      data-testid="multi-storyline-container"
    >
      { packageTitle && (
        <div className="multi-storyline-container__package-title founders-cond">
          <LinkIfHref href={packageTitleUrl}>
            { packageTitle }
          </LinkIfHref>
        </div>
      )}

      <ColumnsContainer
        leadColumn={leadColumn}
        stackLeadColumnFirstOnMobile={stackLeadColumnFirstOnMobile}
        verticalDividers={verticalDividersList}
      >
        {columnsJsx}
      </ColumnsContainer>
      { showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </div>
  );
}

MultiStorylineContainer.propTypes = {
  content: PackagePropType.isRequired,
};
