import React, { useEffect, useState } from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import BadgeLabel from 'components/BadgeLabel';
import { BADGES } from 'components/BadgeLabel/constants';
import { Headline } from 'components/packages/Storyline/Headline';
import classNames from 'classnames';
import { StorylineMedia } from 'components/packages/Storyline/StorylineMedia';
import { BylineList } from 'components/Byline';
import Link from 'components/Link';
import ContentTimestamp from 'components/ContentTimestamp';
import LiveBlog from 'components/packages/LiveBlog';
import Breakpoints from 'lib/Breakpoints';

import './styles.themed.scss';

export function LiveBlogLayout({ content }) {
  const packageMetadata = content.metadata;
  const {
    metadata: {
      layoutType: storyType = 'standard',
      mediaSize = 'medium',
      headlineSize = 'large',
    },
    subType,
    items: [
      {
        computedValues: {
          url,
          dek,
          headline: computedHeadline,
          unibrow,
        },
        item,
        metadata: {
          hasBadge,
          hasEyebrow = true,
          badge,
          showAuthor: hasAuthor = false,
          showTimestamp,
          showDek,
          badgeText,
        },
      },
    ],
  } = content;

  const storyTypes = {
    IMPORTANT: 'important',
    STANDARD: 'standard',
  };

  const hasTwoArticles = subType === 'additionalLiveBlog' || subType === 'additionalChatBlog';
  const isChatStyle = subType === 'additionalChatBlog';
  const mainItem = content.items[0];
  const delimiter = '/';
  const showMedia = mediaSize !== 'none';
  const showHeadlineOnly = !hasAuthor && !showDek && !showTimestamp && !showMedia;
  const showAuthor = hasAuthor && item?.authors?.length > 0;
  const isImportantStoryType = storyType === storyTypes.IMPORTANT;
  const { metadata: { headlineUrlOverride } } = mainItem;
  const liveBlogHeadline = computedHeadline || mainItem?.metadata?.embedHeadline;
  const liveBlogHeadlineUrl = (mainItem.type === 'video' && headlineUrlOverride)
    ? headlineUrlOverride : (mainItem?.metadata?.embedHeadlineUrl || url);
  const liveBlogEyebrow = unibrow?.text || mainItem?.computedValues?.unibrow?.text;
  const liveBlogEyebrowUrl = unibrow?.url?.primary
  || mainItem?.computedValues?.unibrow?.url?.primary;
  const liveBlogShowDek = showDek || mainItem?.metadata?.showDek;
  const liveBlogDek = dek || mainItem?.computedValues?.dek;
  const [showStorylineMediaLeftOrRight, setShowStorylineMediaLeftOrRight] = useState('left');

  useEffect(() => {
    const breakpointListener = () => {
      if (Breakpoints.isXL()) {
        return isImportantStoryType ? setShowStorylineMediaLeftOrRight('left') : setShowStorylineMediaLeftOrRight('right');
      }
      return setShowStorylineMediaLeftOrRight('left');
    };

    Breakpoints.getXLargeMQL().addEventListener('change', breakpointListener);

    breakpointListener();

    return () => Breakpoints.getXLargeMQL().removeEventListener('change', breakpointListener);
  }, []);

  const isInlineBadge = isChatStyle && !isImportantStoryType;

  const getBadgeType = () => {
    if (hasBadge && !badge) {
      return 'BREAKING';
    }
    return (hasBadge && badge) || BADGES.EYEBROW;
  };

  const liveBlogDisplay = (
    <LiveBlog
      content={{
        ...content,
        items: [
          {
            ...content.items[hasTwoArticles ? 1 : 0],
          },
        ],
      }}
      showLiveBadge
    />
  );

  const innerComponents = () => (
    <div className={classNames({
      'headline-center': isImportantStoryType && headlineSize === 'large',
      'pb0-xl pb6-l pb5-m': isInlineBadge,
    })}
    >
      <div className={classNames('liveblog-layout__badge', { isInline: isInlineBadge })} data-testid="liveblog-layout-badge">
        {hasEyebrow && (
          <BadgeLabel
            badgeType={getBadgeType()}
            badgeText={badgeText}
            eyebrowText={liveBlogEyebrow}
            url={liveBlogEyebrowUrl}
          />
        )}
      </div>
      <Link to={liveBlogHeadlineUrl}>
        <Headline
          text={liveBlogHeadline}
          storyType={storyType}
          headlineSize={headlineSize}
          showHeadlineOnly={showHeadlineOnly}
          className={classNames({ di: isInlineBadge })}
        />
        {liveBlogShowDek && (
          <div data-testid="storyline-liveblog-dek" className="liveblog-layout__dek publico-txt f3 lh-copy fw4 mb2">
            {liveBlogDek}
          </div>
        )}
        <div
          data-testid="storyline-liveblog-author"
          className={classNames(
            'liveblog-layout__author-timestamp-container',
            'founders-mono',
            'lh-none',
            'fw4',
            { 'bottom-margin-with-media': showMedia },
          )}
        >
          {showAuthor && (
            <BylineList authors={item?.authors} />
          )}
          {showAuthor && showTimestamp && (
            <span data-testid="storyline-liveblog-delim" className="ma1">{delimiter}</span>
          )}
          {showTimestamp && (
            <span data-testid="storyline-liveblog-timestamp">
              <ContentTimestamp
                dateCreated={item?.dateCreated || ''}
                datePublished={item?.datePublished || ''}
                delimiter=", "
                showCreatedDate
              />
            </span>
          )}
        </div>
      </Link>
    </div>
  );

  const isMultiStoryline = content.type === 'MultiStoryline';

  return (
    <div
      className={classNames('liveblog-layout', {
        'important-story': isImportantStoryType,
        'standard-story': storyType !== storyTypes.IMPORTANT,
        onlyBlog: !hasTwoArticles,
        additionalBlog: hasTwoArticles,
      })}
      data-testid={`storyline-liveblog-layout${hasTwoArticles && '-additionalBlog'}`}
    >
      <div className="liveblog-layout__container-top">
        {innerComponents()}
      </div>
      <div className="liveblog-layout__main-content-container">
        <div
          className={classNames(
            'liveblog-layout__container-side',
            `media-${mediaSize}`,
          )}
        >
          {!isImportantStoryType && (
            <div
              className={classNames('container-side__content', {
                'no-dek': !dek,
                'no-author-timestamp': !showAuthor && !showTimestamp,
              })}
            >
              {innerComponents()}
            </div>
          )}
          {!isMultiStoryline && showMedia && showStorylineMediaLeftOrRight === 'left' && (
            <div className="media-container-left">
              <StorylineMedia
                contentItem={mainItem}
                packageMetadata={packageMetadata}
                isLiveBlogLayout
              />
            </div>
          )}
          <div
            className={classNames('blog-container-left', {
              'is-second-slot': hasTwoArticles,
            })}
            data-testid="liveblog-layout-blogcontainer"
          >
            {liveBlogDisplay}
          </div>
        </div>
        <div className="liveblog-layout__content-container-right">
          {showMedia && showStorylineMediaLeftOrRight === 'right' && (
            <div className="media-container-right">
              <StorylineMedia
                contentItem={mainItem}
                packageMetadata={packageMetadata}
                isLiveBlogLayout
              />
            </div>
          )}
          <div
            className={classNames('blog-container-right', {
              'has-headline': hasTwoArticles,
            })}
            data-testid="liveblog-layout-blogcontainer"
          >
            {liveBlogDisplay}
          </div>
        </div>
      </div>
    </div>
  );
}

LiveBlogLayout.propTypes = {
  content: PackagePropType.isRequired,
};
